import { LatLngExpression } from 'leaflet';
import { Props } from './types';
import { useMapConfig } from '../../hooks/useMapConfig';
import { MapWorkRoomControls } from './MapWorkRoomControls/MapWorkRoomControls';
import styles from './styles.module.scss';
import pointIcon from '../../assets/images/point.svg';
import { Map } from '../Map/Map';

export const MapWorkRoom: React.FC<Props> = props => {
  const mapImageUrl = `data:image/jpeg;base64,${props.mapImage}`;
  const {
    isReady: mapIsReady,
    imageDimensions,
    mapContainerRef,
  } = useMapConfig(mapImageUrl);

  const center: LatLngExpression = [imageDimensions.height - props.y, props.x];

  const iconPosition: [number, number] = [24, 40];

  const points = [
    {
      y: imageDimensions.height - props.y,
      x: props.x,
      icon: {
        html: `<img src=${pointIcon} style="max-width: 100%!important" />`,
        className: styles.marker,
        width: 44,
        height: 44,
        position: iconPosition,
      },
    },
  ];

  const mapProps = {
    container: {
      tap: false,
      center: center,
      maxZoom: 0.5,
      minZoom: -3,
      zoomSnap: 0.25,
      zoomControl: false,
      transparent: false,
    },
    markers: {
      points: points,
    },
    image: {
      width: imageDimensions.width,
      height: imageDimensions.height,
      url: mapImageUrl,
    },
    defaultControls: false,
  };

  return (
    <div
      id="map-detail-container"
      ref={mapContainerRef}
      className={styles.mapContainer}
    >
      {mapIsReady && (
        <Map {...mapProps}>
          <MapWorkRoomControls
            maxZoom={mapProps.container.maxZoom}
            minZoom={mapProps.container.minZoom}
            center={center}
          />
        </Map>
      )}
    </div>
  );
};
