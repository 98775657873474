import {
  base64FromPath,
  getImageFormat,
  isEmpty,
} from '../../../utils/functions';
import { Building, Incident } from '../../models/incident.model';
import muleSoftInstance from '../../utils/httpCommon';
import dayjs from 'dayjs';

const getCategories = async (
  language: string,
  allCatetories: boolean,
): Promise<any> => {
  const path = `${process.env.REACT_APP_API_HABITAT}/v1/incidences/categories`;
  try {
    const resp = await muleSoftInstance.get(path, {
      params: { language: language, disabled: allCatetories },
    });
    return resp.data;
  } catch (err) {
    throw new Error(err);
  }
};

const getSubcategories = async (idCat, language): Promise<any> => {
  const path = `${process.env.REACT_APP_API_HABITAT}/v1/incidences`;
  try {
    const resp = await muleSoftInstance.get(path, {
      params: { categoryId: idCat, language: language },
    });
    return resp.data;
  } catch (err) {
    throw new Error(err);
  }
};
const getBuildingsAndFloors = async (
  showHiddenBuildings: boolean,
): Promise<Building[]> => {
  const path = `${process.env.REACT_APP_API_HABITAT}/buildingsAndFloors`;
  try {
    const resp = await muleSoftInstance.get(path, {
      params: { hidden: showHiddenBuildings },
    });
    return resp.data;
  } catch (err) {
    throw new Error(err);
  }
};

const reportIncident = async (incident: Incident): Promise<any> => {
  try {
    switch (incident.actionType) {
      case 2:
        //email
        incident.recipient = '';
        await reportEmailIncident(
          `${process.env.REACT_APP_API_NOTIFICATIONS}/registerIncidenceByEmail`,
          incident,
        );
        break;
      case 3:
        //servicenow TIC
        incident.description = `${incident.description}\n${incident.location}`;
        await reportServicenowGmaoIncident(
          `${process.env.REACT_APP_API_NOTIFICATIONS}/registerIncident`,
          incident,
        );
        break;
      case 4:
        //gmao
        await reportServicenowGmaoIncident(
          `${process.env.REACT_APP_API_NOTIFICATIONS}/gmao/incidence`,
          incident,
        );
        break;
      case 5:
        //servicenow Services
        await reportServicenowGmaoIncident(
          `${process.env.REACT_APP_API_NOTIFICATIONS}/registerServicenowIncidence`,
          incident,
        );
        break;
      default:
        break;
    }
  } catch (err) {
    throw new Error(err);
  }
};

const reportEmailIncident = async (path, incident) => {
  try {
    const base64Images = !isEmpty(incident.images)
      ? await Promise.all(
          incident.images?.map(async (path, index) => {
            const img = await base64FromPath(path);
            return {
              contentBytes: img.data.replace(
                `data:${img.mimeType};base64,`,
                '',
              ),
              fileName: `image${index}.${img.mimeType.split('/')[1]}`,
              contentType: img.mimeType,
            };
          }),
        )
      : [];
    const docs = incident.docs?.map(doc => {
      return {
        contentBytes: doc.fichero,
        fileName: doc.nombreFichero,
        contentType: doc.mimeType,
      };
    });
    const params = {
      actionType: incident.actionType,
      categoryId: incident.categoryId,
      incidenceId: incident.incidenceId,
      usubcategory: incident.incidenceId,
      email: {
        attachments: base64Images.concat(docs),
        mailTo: incident.recipient,
        messageBody: incident.description,
        subject: incident.type,
      },
      employeeName: incident.userName,
      location: incident.location,
      type: incident.type,
    };
    const resp = await muleSoftInstance.post(path, params);
    return resp.data;
  } catch (err) {
    throw new Error(err);
  }
};

const reportServicenowGmaoIncident = async (path, incident) => {
  try {
    const base64Images = !isEmpty(incident.images)
      ? await Promise.all(
          incident.images?.map(async (path, index) => {
            const img = await base64FromPath(path);
            return {
              fichero: img.data.replace(`data:${img.mimeType};base64,`, ''),
              nombreFichero: `image${index}.${getImageFormat(
                img.mimeType.split('/')[1],
              )}`,
              descripcion: `Imagen ${index}`,
            };
          }),
        )
      : [];

    const params = {
      type: incident.type,
      date: dayjs().utc().toISOString(),
      description: incident.description,
      location: incident.location,
      categoryId: incident.categoryId,
      actionType: incident.actionType.toString(),
      incidenceId: incident.incidenceId,
      images: base64Images,
      docs: incident.docs,
      locationInfo: incident.locationInfo,
    };

    const resp = await muleSoftInstance.post(path, params);
    return resp.data;
  } catch (err) {
    throw new Error(err);
  }
};

const getUserIncidents = async () => {
  const path = `${process.env.REACT_APP_API_NOTIFICATIONS}/incidents`;
  try {
    const resp = await muleSoftInstance.get(path);
    return resp.data.filter(item => !isEmpty(item.incidence));
  } catch (err) {
    throw new Error(err);
  }
};

export const incidentServices = {
  getCategories,
  getSubcategories,
  getUserIncidents,
  getBuildingsAndFloors,
  reportIncident,
};
