import L from 'leaflet';

export const createPointerIcon = ({
  html,
  className,
  width,
  height,
  position,
  isClicked,
}) =>
  new L.divIcon({
    className: `${className} ${isClicked ? 'icon-clicked' : ''}`,
    html: html,
    iconSize: [width, height], // size of the icon
    iconAnchor: position, // changed marker icon position
  });

export const addMarkers = (map, positions, onClick) => {
  positions.forEach(position => {
    L.marker([position.y, position.x], {
      icon: createPointerIcon(position.icon),
    })
      .on('click', () => {
        onClick ? onClick(position) : () => {};
      })
      .addTo(map);
  });
};

export const addMarkerCluster = (map, positions, onClick) => {
  const markers = L.markerClusterGroup({
    disableClusteringAtZoom: 1,
    spiderfyOnMaxZoom: false,
  });

  positions.forEach(position => {
    const marker = L.marker([position.y, position.x], {
      icon: createPointerIcon(position.icon),
    }).on('click', () => {
      onClick ? onClick(position) : () => {};
    });
    markers.addLayer(marker);
  });

  map.addLayer(markers);
};

export const appendMarkers = (map, markers) => {
  map.eachLayer(layer => {
    if (layer['_layers'] != undefined) layer.remove();
  });
  const onClick = markers.onClickMarker
    ? position => markers.onClickMarker(position)
    : null;

  if (markers.cluster) {
    addMarkerCluster(map, markers.points, onClick);
  } else {
    addMarkers(map, markers.points, onClick);
  }
};
