import L from 'leaflet';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js';

export const togglePseudoFullscreen = isFullscreen => {
  let visibility = 'visible';
  let display = 'block';
  const container = L.DomUtil.get('map-container');
  const detailHeader = document.getElementById('map-detail-header');
  const detailCard = document.getElementById('map-detail-card');

  if (!isFullscreen) {
    L.DomUtil.addClass(container, 'custom-map-fullscreen');
    L.DomUtil.addClass(container, 'leaflet-pseudo-fullscreen');
    visibility = 'hidden';
    display = 'none';
  } else {
    L.DomUtil.removeClass(container, 'custom-map-fullscreen');
    L.DomUtil.removeClass(container, 'leaflet-pseudo-fullscreen');
    visibility = 'visible';
    display = 'block';
  }

  document.querySelectorAll('body *:not(#map-container *)').forEach(e => {
    if (!e.querySelector('#map-container *')) {
      e.style.visibility = visibility;
    }
  });

  if (detailCard) {
    detailCard.style.display = display;
  }
  if (detailHeader) {
    detailHeader.style.display = display;
  }
};

export const addFullscreenControl = (map, options = {}) => {
  // create a fullscreen button and add it to the map
  L.control
    .fullscreen({
      position: 'topleft', // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
      title: 'Full Screen', // change the title of the button, default Full Screen
      titleCancel: 'Exit Full Screen', // change the title of the button when fullscreen is on, default Exit Full Screen
      content: null, // change the content of the button, can be HTML, default null
      forceSeparateButton: false, // force separate button to detach from zoom buttons, default false
      forcePseudoFullscreen: false, // force use of pseudo full screen even if full screen API is available, default false
      fullscreenElement: false, // Dom element to render in full screen, false by default, fallback to map._container
      ...options,
    })
    .addTo(map);

  // map.on('fullscreenchange', function () {
  //   const header = document.getElementById('map-selector-header');
  //   const footer = document.getElementById('map-selector-footer');
  //   const sheetModal = document.getElementById('map-selector-sheet-modal');

  //   if (isPlatform('ios') && map.isFullscreen()) {
  //     header.style.visibility = 'hidden';
  //     footer.style.visibility = 'hidden';
  //     sheetModal.style.visibility = 'hidden';
  //     console.log('entered fullscreen');
  //   } else if (isPlatform('ios')) {
  //     header.style.visibility = 'visible';
  //     footer.style.visibility = 'visible';
  //     sheetModal.style.visibility = 'visible';
  //     console.log('exited fullscreen');
  //   }
  // });
};
