import { history } from '../../_helpers/history';
import {
  Building,
  Category,
  Incident,
  IncidentAction,
  Subcategory,
} from '../models/incident.model';
import { incidentServices } from '../services/incident/incident.services';
import { incidentTypesEnum } from '../types/incident.types.enum';
// import _ from 'lodash';

const incidentRequest = (): IncidentAction => {
  return {
    type: incidentTypesEnum.REQUEST,
  };
};

const fetchIncidentFailure = (error: string): IncidentAction => {
  return {
    type: incidentTypesEnum.FAILURE_INCIDENT,
    error: error,
  };
};

const setUserIncidents = (incidents: Incident[]): IncidentAction => ({
  type: incidentTypesEnum.SET_USER_INCIDENTS,
  incidents: incidents,
});

const setCategories = (categories: Category[]): IncidentAction => ({
  type: incidentTypesEnum.SET_CATEGORIES,
  categories: categories,
});

const setSubcategories = (subcategories: Subcategory[]): IncidentAction => ({
  type: incidentTypesEnum.SET_SUBCATEGORIES,
  subcategories: subcategories,
});
const setBuildingsAndFloors = (
  buildingsAndFloors: Building[],
): IncidentAction => ({
  type: incidentTypesEnum.SET_BUILDINGS_FLOORS,
  buildingsAndFloors: buildingsAndFloors,
});

const setIncident = (incident: Incident): IncidentAction => ({
  type: incidentTypesEnum.SET_INCIDENT,
  incident: incident,
});

const getCategories = (language: string, allCatetories: boolean = null) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(incidentRequest());
    try {
      const categories = await incidentServices.getCategories(
        language,
        allCatetories,
      );
      dispatch(setCategories(categories));
      dispatch(getBuildingsAndFloors());
    } catch (e) {
      dispatch(fetchIncidentFailure(e.message));
    }
  };
};

const getSubcategories = (category: string, language: string) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(incidentRequest());
    try {
      const subcategories = await incidentServices.getSubcategories(
        category,
        language,
      );
      dispatch(setSubcategories(subcategories));
    } catch (e) {
      dispatch(fetchIncidentFailure(e.message));
    }
  };
};
const getBuildingsAndFloors = () => {
  const showHiddenBuildings = true;
  return async (dispatch: any): Promise<any> => {
    dispatch(incidentRequest());
    try {
      const buildingsAndFloors = await incidentServices.getBuildingsAndFloors(
        showHiddenBuildings,
      );
      dispatch(setBuildingsAndFloors(buildingsAndFloors));
    } catch (e) {
      dispatch(fetchIncidentFailure(e.message));
    }
  };
};

const reportIncident = (incident: Incident) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(incidentRequest());
    try {
      await incidentServices.reportIncident(incident);
      delete incident.images;
      delete incident.docs;
      dispatch(setIncident(incident));

      history.replace('/incidents/confirmation', {
        title: 'successful_incident',
        msg: 'incident_info',
        router: '/incidents/categories',
      });
    } catch (e) {
      dispatch(fetchIncidentFailure(e.message));
    }
  };
};

const getUserIncidents = (language: string) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(incidentRequest());
    try {
      const incidents: Incident[] = await incidentServices.getUserIncidents();
      dispatch(setUserIncidents(incidents));
      dispatch(getCategories(language, true));
    } catch (e) {
      dispatch(fetchIncidentFailure(e.message));
    }
  };
};

export const incidentActions = {
  getCategories,
  getSubcategories,
  getUserIncidents,
  getBuildingsAndFloors,
  reportIncident,
};
