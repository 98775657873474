import { TEMPORAL_VISITS_DISTRICT } from '../../utils/constants';
import { AppAction, AppState } from '../models/app.model';
import { appTypesEnum } from '../types';

const initialState: AppState = {
  offlineNetwork: false,
  offlineServices: false,
  offlineAlertHasBeenShown: false,
  offlineToastIsOpen: false,
  noReservationPermissionToastIsOpen: false,
  theme: 'light',
  globalSettings: {
    configurationId: null,
    checkInPreviousTime: null,
    checkInLastTime: null,
    maxRequestDays: null,
    maxFavoriteDesks: 10,
    maxIncidentsDisplay: 10,
    emergencyPhone: null,
    plannedReservationsConfiguration: null,
    lastMinuteReservationsConfiguration: null,
    lastminuteReservationsWeeksExtend: null,
    dashboardNewsCarouselMobile: null,
    dashboardOtherNews: null,
    searcherIndexNews: null,
    newsIndexCarousel: null,
    newsIndexOutstandingNews: null,
    newsIndexOtherNews: null,
    dashboardNewsCarouselDesktop: null,
    numberOfDays: null,
    dayLastMinuteTurningPoint: null,
    hourLastMinuteTurningPoint: null,
    areaServiceMapLink: null,
    restaurantsLink: null,
    ethicalChannelLink: null,
    accionaTodayLink: null,
    recaderiaLink: null,
    campusTimeZone: 'Europe/Madrid',
    colors: [],
    temporalVisitsDistrict: TEMPORAL_VISITS_DISTRICT,
    newActivationCodeMinutes: 10,
    lockerEmployeeKey: null,
    deskDisabledDays: [],
    homeOfficeReservationAvailable: false,
    gymDeepLink: null,
    gymExternalLink: 'https://campus.gymatwork.es/',
    plannedReservation: false,
    lastMinuteReservation: false,
    canteenReservationLastHour: null,
    isPresenceMandatory: false,
  },
  localSettings: {
    localLanguage: 'es',
    viewReservations: 'perDay',
    sedeFilterReservations: [],
  },
  cookiePreferences: {
    ga_: false,
  },
  isLoading: false,
  error: null,
  welcomeText: null,
  dashboardImage: null,
};

export const appReducer = (
  state: AppState = initialState,
  action: AppAction,
): any => {
  switch (action.type) {
    case appTypesEnum.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case appTypesEnum.SET_THEME:
      return {
        ...state,
        theme: action.theme,
      };
    case appTypesEnum.SET_GLOBAL_SETTINGS:
      return {
        ...state,
        error: null,
        isLoading: false,
        globalSettings: {
          ...state.globalSettings,
          ...action.globalSettings,
        },
      };
    case appTypesEnum.SET_WELCOME_TEXT:
      return {
        ...state,
        error: null,
        isLoading: false,
        welcomeText: action.welcomeText,
      };
    case appTypesEnum.SET_DASHBOARD_IMAGE:
      return {
        ...state,
        error: null,
        isLoading: false,
        dashboardImage: action.dashboardImage,
      };
    case appTypesEnum.SET_LOCAL_SETTINGS:
      return {
        ...state,
        localSettings: action.localSettings,
      };
    case appTypesEnum.SET_COOKIE_PREFERENCES:
      return {
        ...state,
        cookiePreferences: action.cookiePreferences,
      };
    case appTypesEnum.FAILURE_REQUEST:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case appTypesEnum.SET_OFFLINE_SERVICES:
      return {
        ...state,
        offlineServices: action.offlineServices,
      };
    case appTypesEnum.SET_OFFLINE_ALERT_HAS_BEEN_SHOWN:
      return {
        ...state,
        offlineAlertHasBeenShown: true,
      };
    case appTypesEnum.SET_OFFLINE_NETWORK:
      return {
        ...state,
        offlineNetwork: action.offlineNetwork,
      };
    case appTypesEnum.SET_OFFLINE_TOAST_IS_OPEN:
      return {
        ...state,
        offlineToastIsOpen: action.offlineToastIsOpen,
      };
    case appTypesEnum.SET_NO_RESERVATION_PERMISSION_TOAST_IS_OPEN:
      return {
        ...state,
        noReservationPermissionToastIsOpen:
          action.noReservationPermissionToastIsOpen,
      };
    case appTypesEnum.RESET_APP_STATE:
      return initialState;
    default:
      return state;
  }
};
