import { useEffect, useRef } from 'react';
import { LatLngBoundsExpression } from 'leaflet';
import { ImageOverlay, useMap } from 'react-leaflet';
import { appendMarkers } from './helpers';
import { MapImageProps as Props } from './types';

export const MapImage: React.FC<Props> = (props: Props) => {
  const { image, markers } = props;
  const isMounted = useRef(true);
  const map = useMap();

  const imageBounds: LatLngBoundsExpression = [
    [0, 0],
    [image.height, image.width],
  ];

  // const mapCenter: LatLngExpression = center ?? [image.height / 2, image.width / 2];
  // const mapInitialZoom: number = initialZoom ?? 0;

  const maxBounds: LatLngBoundsExpression = [
    [-50, -50], // top, left
    [image.height + 50, image.width + 50], // bottom, right
  ];

  useEffect(() => {
    if (isMounted.current) {
      // map.setView(mapCenter, mapInitialZoom);
      map.fitBounds(imageBounds);
      map.setMaxBounds(maxBounds);
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const hasMarkers = markers?.points && markers?.points.length > 0;

  if (hasMarkers) {
    appendMarkers(map, markers); // append markers to the map
  }

  return <ImageOverlay bounds={imageBounds} url={image.url} />;
};
