import { useLayoutEffect, useState } from 'react';

export const useDelayedMapRendering = () => {
  const [renderMap, setRenderMap] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => setRenderMap(true), 500);
    return setRenderMap(false);
  }, []);

  return renderMap;
};
