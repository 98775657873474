import { useEffect, useState } from 'react';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import { MapControlsProps as Props } from './types';
import { IonFab, IonFabButton, IonIcon, isPlatform } from '@ionic/react';
import { scanOutline } from 'ionicons/icons';
import { Control } from './Control';
import { addFullscreenControl, togglePseudoFullscreen } from './helpers';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import styles from './MapControlsDefault.module.scss';

export const MapControlsDefault: React.FC<Props> = (props: Props) => {
  const map = useMap();
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    if (!document.querySelector('.leaflet-control-fullscreen-button')) {
      addFullscreenControl(map, {
        forcePseudoFullscreen: isPlatform('ios'),
      });
    }
  }, []);

  const disableClickEventPropagation = (buttonId: string) => {
    const button = L.DomUtil.get(buttonId);
    button && L.DomEvent.disableClickPropagation(button);
  };

  const handleFullScreen = () => {
    const button: HTMLElement | null = document.querySelector(
      '.leaflet-control-fullscreen-button',
    );
    disableClickEventPropagation('fullscreen-control');

    if (isPlatform('ios')) {
      togglePseudoFullscreen(isFullscreen);
      setIsFullscreen(!isFullscreen);
    } else {
      button && button.click();
    }
  };

  const handleZoomIn = () => {
    disableClickEventPropagation('zoom-in-control');
    if (map.getZoom() < props.maxZoom) {
      map.setView(map.getCenter(), map.getZoom() + 1);
    }
  };

  const handleZoomOut = () => {
    disableClickEventPropagation('zoom-out-control');
    if (map.getZoom() > props.minZoom) {
      map.setView(map.getCenter(), map.getZoom() - 1);
    }
  };

  const handleDistrictFocus = () => {
    disableClickEventPropagation('district-focus-control');
    map.setView(props.center);
  };

  const DistrictFocusButton = (
    <IonFabButton
      id={'district-focus-control'}
      className={styles.fabBtn}
      color="primary"
      onClick={handleDistrictFocus}
    >
      <IonIcon className="icon icon-neighbourhood" />
    </IonFabButton>
  );

  const ZoomInButton = (
    <IonFabButton
      id="zoom-in-control"
      className={styles.fabBtn}
      color="primary"
      onClick={handleZoomIn}
    >
      <IonIcon className="icon icon-plus" />
    </IonFabButton>
  );

  const ZoomOutButton = (
    <IonFabButton
      id="zoom-out-control"
      className={styles.fabBtn}
      color="primary"
      onClick={handleZoomOut}
    >
      <IonIcon className="icon icon-minus" />
    </IonFabButton>
  );

  const FullScreenButton = (
    <IonFabButton
      id="fullscreen-control"
      className={styles.fabBtn}
      color="primary"
      onClick={handleFullScreen}
    >
      <IonIcon icon={scanOutline} className={`icon ${styles.fullScreenIcon}`} />
    </IonFabButton>
  );

  return (
    <IonFab vertical="bottom" horizontal="end" className={styles.bottomRight}>
      <Control position="bottomright">{DistrictFocusButton}</Control>
      <Control position="bottomright">{FullScreenButton}</Control>
      <Control position="bottomright">{ZoomInButton}</Control>
      <Control position="bottomright">{ZoomOutButton}</Control>
    </IonFab>
  );
};
