import {
  Button,
  FilterChip,
  Item,
  List,
  Snackbar,
  Toast,
  TopBar,
} from '@acciona/ui-ionic-kit';
import {
  IonBackButton,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
} from '@ionic/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IncidentStore } from '../../../_redux/models/incident.model';
import { incidentActions } from '../../../_redux/actions';
import { useHistory } from 'react-router';
import { isEmpty } from '../../../utils/functions';
import { AppStore } from '../../../_redux/models/app.model';
import { IncidentItem } from './IncidentItem';

const PersonalIncidence: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filterType, setFilterType] = useState([]);
  const [userIncidents, setUserIncidents] = useState<any[]>([]);
  const history = useHistory();
  const [selectedFilter, setSelectedFilter] = useState('all');
  const { incidents, categories, isLoading, error } = useSelector(
    (store: IncidentStore) => store.incident,
  );
  const maxIncidentsDisplay = useSelector(
    (store: AppStore) => store.app.globalSettings.maxIncidentsDisplay,
  );
  const { localLanguage } = useSelector(
    (store: AppStore) => store.app.localSettings,
  );

  useEffect(() => {
    dispatch(incidentActions.getUserIncidents(localLanguage));
  }, []);

  useEffect(() => {
    !isEmpty(categories) && setFilterType(categories);
  }, [categories]);

  useEffect(() => {
    if (!isLoading) {
      const sortedIncidents = !isEmpty(incidents)
        ? [...incidents].sort((x, y) => +new Date(y.date) - +new Date(x.date))
        : [];

      const nextIncidents = sortedIncidents.slice(0, maxIncidentsDisplay);

      setUserIncidents(nextIncidents);
    }
  }, [incidents, isLoading]);

  const countByType = (incidents, type) => {
    return incidents.filter(
      incidents => incidents?.incidence?.categoryId == type.toString(),
    ).length;
  };

  const handleSpaceFilterChange = data => {
    const { id } = data;
    setSelectedFilter(id);
    setFilterType(
      [...filterType].map(object => {
        if (object.id != id) {
          return {
            ...object,
            checked: false,
          };
        } else if (object.checked === false) {
          return {
            ...object,
            checked: true,
          };
        } else {
          return { ...object };
        }
      }),
    );
  };

  const filteredIncidents = useMemo(() => {
    return userIncidents.filter(
      incident =>
        selectedFilter === 'all' ||
        incident.incidence?.categoryId == selectedFilter,
    );
  }, [userIncidents, selectedFilter]);

  const activeCategories = useMemo(() => {
    return filterType.filter(
      category => countByType(userIncidents, category.categoryId) > 0,
    );
  }, [userIncidents, filterType]);

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/profile/home"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_profile_personal_incidence')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.gridWeb}>
          {!isLoading &&
            (isEmpty(filteredIncidents) || isEmpty(activeCategories) ? (
              <>
                <IonRow className="ion-justify-content-center">
                  <IonCol className={styles.center}>
                    <IonLabel className={styles.title_2}>
                      {t('not_available_incidents')}
                    </IonLabel>
                  </IonCol>
                </IonRow>
              </>
            ) : (
              <>
                <Snackbar
                  icon="icon icon-info"
                  type="info"
                  text={t('lbl_incidence_recordatory', {
                    number: maxIncidentsDisplay,
                  })}
                  align="left"
                />
                <Item className={styles.filters} lines="full">
                  <div className={styles.gridscroll}>
                    {userIncidents &&
                      filterType &&
                      filterType.map((item, index) => {
                        return (
                          item.id !== 'all' &&
                          countByType(userIncidents, item.categoryId) > 0 && (
                            <FilterChip
                              key={index}
                              id={item.categoryId}
                              text={`${t(item.name)} (${countByType(
                                userIncidents,
                                item.categoryId,
                              )})`}
                              checked={item.checked}
                              onChange={handleSpaceFilterChange}
                              icon={`icon icon-${item.icon}`}
                            />
                          )
                        );
                      })}
                  </div>
                </Item>
                <List>
                  {!isEmpty(filteredIncidents)
                    ? filteredIncidents.map(
                        (incident, index) =>
                          filterType.find(
                            filter =>
                              filter.categoryId ==
                              incident?.incidence?.categoryId,
                          ) && (
                            <IncidentItem
                              key={index}
                              incident={incident}
                              filterType={filterType}
                            />
                          ),
                      )
                    : null}
                </List>
              </>
            ))}
          <Toast
            isOpen={!!error}
            message={error}
            position="bottom"
            type="error"
          />
          <IonLoading
            isOpen={isLoading}
            message={t('msg_loading')}
            duration={10000}
          />
        </div>
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button
              onClick={() =>
                history.replace('/incidents/categories', {
                  state: true,
                })
              }
            >
              {t('btn_create_incident')}
            </Button>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default PersonalIncidence;
