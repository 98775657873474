import { IncidentAction, IncidentState } from '../models/incident.model';
import { incidentTypesEnum } from '../types';

const initialState = {
  error: null,
  isLoading: false,
  incident: null,
  incidents: [],
  categories: [],
  subcategories: [],
  buildingsAndFloors: [],
};

export const incidentReducer = (
  state: IncidentState = initialState,
  action: IncidentAction,
): IncidentState => {
  switch (action.type) {
    case incidentTypesEnum.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case incidentTypesEnum.SET_USER_INCIDENTS:
      return {
        ...state,
        isLoading: false,
        error: null,
        incidents: action.incidents,
      };
    case incidentTypesEnum.SET_INCIDENT:
      return {
        ...state,
        isLoading: false,
        error: null,
        incident: action.incident,
      };
    case incidentTypesEnum.SET_CATEGORIES:
      return {
        ...state,
        isLoading: false,
        error: null,
        categories: action.categories,
      };
    case incidentTypesEnum.SET_SUBCATEGORIES:
      return {
        ...state,
        isLoading: false,
        error: null,
        subcategories: action.subcategories,
      };
    case incidentTypesEnum.SET_BUILDINGS_FLOORS:
      return {
        ...state,
        isLoading: false,
        error: null,
        buildingsAndFloors: action.buildingsAndFloors,
      };
    case incidentTypesEnum.FAILURE_INCIDENT:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
