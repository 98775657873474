import { CRS } from 'leaflet';
import 'leaflet.markercluster';
import { MapProps as Props } from './types';
import { MapContainer } from 'react-leaflet';
import { MapControlsDefault } from './MapControlsDefault';
import { MapImage } from './MapImage';
import { useDelayedMapRendering } from './useDelayedMapRendering';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import './styles.scss';

export const Map: React.FC<Props> = (props: Props) => {
  const { container, markers, image, defaultControls, children } = props;
  const renderMap = useDelayedMapRendering();

  return (
    <>
      {renderMap && image && (
        <MapContainer
          id="map-container"
          {...container}
          crs={image ? CRS.Simple : container.crs}
        >
          <MapImage
            image={image}
            markers={markers}
            center={container.center}
            initialZoom={container.initialZoom}
          />
          {children}
          {defaultControls && (
            <MapControlsDefault
              maxZoom={props.container.maxZoom ?? 2}
              minZoom={props.container.minZoom ?? -3}
              center={props.container.center ?? [0, 0]}
            />
          )}
        </MapContainer>
      )}
    </>
  );
};
